import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-balta-la-nuci',
  templateUrl: './balta-la-nuci.component.html',
  styleUrls: []
})
export class BaltaLaNuciComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
