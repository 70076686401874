import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PublicLayoutComponent} from './public-layout.component';
import {BlockUIModule} from 'ng-block-ui';
import {NavMenuComponent} from '../../common/nav-menu/nav-menu.component';
import {FooterComponent} from '../../common/footer/footer.component';
import {CommonModule} from '@angular/common';
import {NavItemsComponent} from '../../common/nav-menu/nav-items/nav-items.component';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    NavMenuComponent,
    FooterComponent,
    NavMenuComponent,
    NavItemsComponent
  ],
  imports: [
    RouterModule,
    BlockUIModule,
    CommonModule
  ],
  exports: [PublicLayoutComponent]
})
export class PublicLayoutModule {
}
