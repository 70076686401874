import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PrivateLayoutComponent} from './private-layout.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    PrivateLayoutComponent,
  ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [PrivateLayoutComponent]
})
export class PrivateLayoutModule {
}
