import {Component, ViewEncapsulation} from '@angular/core';
import {paths} from '../../app-paths';

@Component({
  selector: 'app-public-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateLayoutComponent {
  paths = paths;

  constructor() {
  }
}
