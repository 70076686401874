import {Component, OnInit} from '@angular/core';
// @ts-ignore
import BaltiJson from '../../../assets/json/balti.json';

@Component({
  selector: 'app-info-balti',
  templateUrl: './materiale-proiect.component.html',
  styleUrls: ['./materiale-proiect.component.scss']
})
export class MaterialeProiectComponent implements OnInit {
  ngOnInit(): void {
  }
}
