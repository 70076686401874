import {NgModule} from '@angular/core';
import {PublicLayoutModule} from './public/public-layout.module';
import {PrivateLayoutModule} from './private/private-layout.module';

@NgModule({
  imports: [
    PublicLayoutModule,
    PrivateLayoutModule
  ],
  exports: [
    PublicLayoutModule,
    PrivateLayoutModule
  ]
})
export class LayoutModule {
}
