import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AcasaComponent} from './acasa/acasa.component';
import {BaltiComponent} from './info/balti/balti.component';
import {HartiComponent} from './info/harti/harti.component';
import {StudiiIstoriceComponent} from './info/studii-istorice/studii-istorice.component';
import {DespreNoiComponent} from './despre-noi/despre-noi.component';
import {GalerieComponent} from './galerie/galerie.component';
import {ContactComponent} from './contact/contact.component';
import {PublicLayoutComponent} from './layouts/public/public-layout.component';
import {PrivateLayoutComponent} from './layouts/private/private-layout.component';
import {paths} from './app-paths';
import {AuthorizeGuard} from '../api-authorization/authorize.guard';
import {MaterialeProiectComponent} from './info/materiale-proiect/materiale-proiect.component';

export const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      // Landing Page
      {
        path: paths.acasa.slug,
        component: AcasaComponent
      },
      {
        path: paths.despreNoi.slug,
        component: DespreNoiComponent
      },
      {
        path: paths.info.slug,
        children: [
          {
            path: paths.info.materialeProiect.slug,
            component: MaterialeProiectComponent
          },
          {
            path: paths.info.balti.slug,
            component: BaltiComponent
          },
          {
            path: paths.info.harti.slug,
            component: HartiComponent
          },
          {
            path: paths.info.studiiIstorice.slug,
            component: StudiiIstoriceComponent
          }
        ]
      },
      {
        path: paths.galerie.slug,
        component: GalerieComponent
      },
      {
        path: paths.stiri.slug,
        children: [
          {
            path: '',
            loadChildren: () => import('./stiri/stiri.module').then(m => m.StiriModule)
          }
        ]
      },
      {
        path: paths.forum.slug,
        children: [
          {
            path: '',
            loadChildren: () => import('./forum/forum.module').then(m => m.ForumModule)
          }
        ]
      },
      {
        path: paths.contact.slug,
        component: ContactComponent
      }
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: paths.admin.slug,
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./admin/admin.module').then(x => x.AdminModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
