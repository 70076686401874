import {Component, Input, OnInit} from '@angular/core';
import {paths} from 'src/app/app-paths';

@Component({
  selector: 'app-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.css']
})
export class NavItemsComponent implements OnInit {

  @Input() customClass: string | undefined;

  constructor() {
  }

  paths = paths;

  ngOnInit() {
  }
}
