import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {paths} from 'src/app/app-paths';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavMenuComponent {
  paths = paths;
  opened = false;

  constructor(private router: Router) {
  }

  isHomepage(): boolean {
    return this.router.isActive(paths.acasa.slug, true);
  }

  search(): void {
  }

  toggle() {
    this.opened = !this.opened;
  }
}
