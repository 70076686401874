import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppComponent} from './app.component';
import {AcasaComponent} from './acasa/acasa.component';
import {ApiAuthorizationModule} from 'src/api-authorization/api-authorization.module';
import {AuthorizeInterceptor} from 'src/api-authorization/authorize.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {DespreNoiComponent} from './despre-noi/despre-noi.component';
import {BaltiComponent} from './info/balti/balti.component';
import {HartiComponent} from './info/harti/harti.component';
import {StudiiIstoriceComponent} from './info/studii-istorice/studii-istorice.component';
import {GalerieComponent} from './galerie/galerie.component';
import {ContactComponent} from './contact/contact.component';
import {BlockUIModule} from 'ng-block-ui';
import {LayoutModule} from './layouts/layout.module';
import {MaterialeProiectComponent} from './info/materiale-proiect/materiale-proiect.component';
import {BaltaIleanaNaipuComponent} from './info/balti/balta-ileana-naipu/balta-ileana-naipu.component';
import {BaltaLaNuciComponent} from './info/balti/balta-la-nuci/balta-la-nuci.component';
import {BaltaGreacaCercaniComponent} from './info/balti/balta-greaca-cercani/balta-greaca-cercani.component';
import {IazulBila2Component} from './info/balti/iazul-bila2/iazul-bila2.component';
import {BaltaCamineascaComponent} from './info/balti/balta-camineasca/balta-camineasca.component';
import {BaltaLaConacComponent} from './info/balti/balta-la-conac/balta-la-conac.component';
import {BaltaIcoanaComponent} from './info/balti/balta-icoana/balta-icoana.component';
import {BaltaPutuGreciComponent} from './info/balti/balta-putu-greci/balta-putu-greci.component';
import {BaltaPutineiuLaConacComponent} from './info/balti/balta-putineiu-la-conac/balta-putineiu-la-conac.component';

@NgModule({
  declarations: [
    AppComponent,

    AcasaComponent,
    DespreNoiComponent,
    MaterialeProiectComponent,
    BaltiComponent,
    HartiComponent,
    StudiiIstoriceComponent,
    GalerieComponent,
    ContactComponent,
    BaltaIleanaNaipuComponent,
    BaltaLaNuciComponent,
    BaltaGreacaCercaniComponent,
    IazulBila2Component,
    BaltaCamineascaComponent,
    BaltaLaConacComponent,
    BaltaIcoanaComponent,
    BaltaPutuGreciComponent,
    BaltaPutineiuLaConacComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiAuthorizationModule,
    AppRoutingModule,
    LayoutModule,
    BlockUIModule.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


