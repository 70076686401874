export const paths = {
  acasa: {
    slug: '',
    fullPath: `/`
  },
  despreNoi: {
    slug: 'despre-noi',
    fullPath: `/despre-noi`
  },
  info: {
    slug: 'info',
    materialeProiect: {
      slug: 'materiale-proiect',
      fullPath: `/info/materiale-proiect`
    },
    balti: {
      slug: 'balti',
      fullPath: `/info/balti`
    },
    harti: {
      slug: 'harti',
      fullPath: `/info/harti`
    },
    studiiIstorice: {
      slug: 'studii-istorice',
      fullPath: `/info/studii-istorice`
    }
  },
  galerie: {
    slug: 'galerie',
    fullPath: `/galerie`
  },
  stiri: {
    slug: 'stiri',
    list: {
      slug: '',
      fullPath: '/stiri'
    },
    view: {
      slug: ':id',
      fullPath: '/stiri/:id'
    }
  },
  forum: {
    slug: 'forum',
    forum: {
      slug: '',
      fullPath: '/forum'
    },
    topics: {
      slug: 'topics/:id',
      fullPath: '/forum/topics/:id'
    },
    posts: {
      slug: 'posts/:id',
      fullPath: '/forum/posts/:id'
    },
    newPost: {
      slug: 'newPost',
      fullPath: '/forum/newPost'
    },
  },
  contact: {
    slug: 'contact',
    fullPath: `/contact`
  },
  admin: {
    slug: 'admin',
    dashboard: {
      slug: '',
      fullPath: `/admin`
    },
    galerieFoto: {
      slug: 'galerie',
      fullPath: '/admin/galerie'
    },
    anunturi: {
      slug: 'anunturi',
      fullPath: '/admin/anunturi'
    },
    links: {
      slug: 'links',
      fullPath: '/admin/links'
    },
    forum: {
      slug: 'forum',
      fullPath: '/admin/forum'
    },
    utilizatori: {
      slug: 'utilizatori',
      fullPath: '/admin/utilizatori'
    }
  }
};
