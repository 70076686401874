import {Component} from '@angular/core';
import {paths} from '../app-paths';

@Component({
  selector: 'app-acasa',
  templateUrl: './acasa.component.html',
  styleUrls: ['./acasa.component.scss']
})
export class AcasaComponent {
  paths = paths;
}
