import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-balta-ileana-naipu',
  templateUrl: './balta-ileana-naipu.component.html',
  styleUrls: []
})
export class BaltaIleanaNaipuComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
