import {Component, OnInit, ViewEncapsulation} from '@angular/core';
// @ts-ignore
import BaltiJson from '../../../assets/json/balti.json';

@Component({
  selector: 'app-info-balti',
  templateUrl: './balti.component.html',
  styleUrls: ['./balti.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaltiComponent implements OnInit {
  uats: UAT[] = [];

  ngOnInit(): void {
    this.uats = BaltiJson['uats'];
  }

  goToMaps(gmaps: string): void {
    window.open(gmaps, '_blank');
  }
}

class Balta {
  name: string;
  gmaps: string;
}

class UAT {
  name: string;
  balti: Balta[];
}
