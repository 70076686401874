import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-galerie',
  templateUrl: './galerie.component.html',
  styleUrls: ['./galerie.component.scss']
})
export class GalerieComponent implements OnInit {
  gallery: string[];

  baseApi = `api/Gallery`;
  photoApi = `api/Image`;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.photoApi = baseUrl + this.photoApi;
  }

  ngOnInit(): void {
    this.loadGallery();
  }

  loadGallery(): void {
    this.httpClient.get<string[]>(this.baseApi).subscribe(res => {
      this.gallery = res;
    });
  }

  getUrl(fileName: string) {
    return `./galerie_foto/${fileName}`;
  }

}
