import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  constructor(private httpClient: HttpClient) {
  }

  formContact: FormGroup;
  submitted: boolean;
  sent: boolean;

  sendEmail(): void {
    this.submitted = true;
    this.sent = false;

    if (!this.formContact.valid) {
      return;
    }

    this.httpClient.post<void>('/api/contact', this.formContact.value).subscribe(() => {
      this.submitted = false;
      this.sent = true;
    }, error => {
      this.sent = false;
    });
  }

  ngOnInit(): void {
    this.submitted = false;
    this.sent = false;
    this.formContact = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required)
    });
  }
}
