import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-iazul-bila2',
  templateUrl: './iazul-bila2.component.html',
  styleUrls: []
})
export class IazulBila2Component implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
