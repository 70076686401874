import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: [
    './style.scss',
    './superfish.scss',
    './public-layout.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PublicLayoutComponent {
  constructor() {
  }
}
